body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edgebutton-foreignobject {
  overflow: visible;
  cursor: pointer;
  width: 20px; /* Smaller width */
  height: 20px; /* Smaller height */
}

.edgebutton {
  background-color: #f0f0f0; /* Light grey background */
  border: 1px solid #c0c0c0; /* Darker grey border */
  color: #808080; /* Grey color for the cross */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Rounded corners */
  font-size: 20px;
}

.react-flow__edge-path-selector:hover {
  cursor: pointer;
}

.react-flow__edge-path-selector:hover + .react-flow__edge-path, 
.react-flow__edge-path:hover {
  stroke: "#D5D7D9" !important;
  cursor: pointer;
}

.react-flow__edge-path-selector {
  fill: none !important;
  stroke: transparent !important;
  stroke-width: 40 !important; /* Increased stroke-width for larger hit area */
}

.react-flow__edge-path {
  stroke-width: 1 !important; /* Match your edge stroke width */
}

.react-flow .react-flow__node {
  border-width: 0 !important;
}

.react-flow .react-flow__node {
  height: 80px;
  width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 700;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 1;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
}